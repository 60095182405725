//use dot as decimal separator
export const formatPrice = (price: number) => {
  //a hax to use de-DE locale instead of is-IS
  return price
    .toLocaleString("de-DE", {
      style: "currency",
      currency: "ISK",
    })
    .replace("ISK", "kr.");
};

//format date to dd.mm.yyyy hh:mm
export const formatDate = (date: string) => {
  const d = new Date(date);
  return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()} ${
    d.getHours() < 10 ? "0" : ""
  }${d.getHours()}:${d.getMinutes() < 10 ? "0" : ""}${d.getMinutes()}`;
};

export const formatPhone = (phone?: string) => {
  if (!phone) {
    return "???-????";
  }
  //7 digits like 123-4567
  return phone.replace(/(\d{3})(\d{4})/, "$1-$2");
};

export const santiesId = (id: string) => {
  return id.replace(/-/g, "");
};
